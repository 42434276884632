.root {
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--text-primary);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-family: 'TWK Everett Mono', sans-serif;
    font-weight: 300;
}

.summary {
    flex: 1;
    min-width: 0;
    padding: var(--spacing-size-four) var(--spacing-size-three);
}

@media screen and (max-width: 991px) {
    .root {
        font-size: var(--font-size-component2);
        line-height: var(--line-height-component2);
    }

    .summary {
        padding: var(--spacing-size-three) var(--spacing-size-two);
        display: grid;
        row-gap: var(--spacing-size-one);
    }
}

.alternative .summary {
    text-align: center;
}

.alternative .variants {
    justify-content: center;
}

.center {
    justify-content: center;
}

.variants {
    display: flex;
    flex-direction: row;
}



.variant {
    padding-right: var(--spacing-size-two);
}

.faded {
    color: var(--surface-secondary);
}

.title {
    max-width: calc(100vw / 5.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 991px) {
    .title {
        max-width: calc(100vw / 1);
    }
}

.pricing {
    display: flex;
    flex-direction: row;
}

.price {
    margin: 0;
}

.compareAtPrice {
    color: var(--color-brand-titanium);
    margin-right: var(--spacing-size-two);
    text-decoration: line-through;
}

.image {
    position: relative;
    background: var(--surface-product);
}

.image > img{
    width: 100%;
    height: auto;
    display: block;
}

.wishlist {
    position: absolute;
    top: var(--spacing-size-two);
    right: 0;
    padding: var(--spacing-size-three) var(--spacing-size-four);
    z-index: 1;
}

.label {
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--spacing-size-three) var(--spacing-size-four);
}

.coloursCount {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: var(--spacing-size-three);
}

.imagePlaceholder {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
}

.video {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    display: block;
    z-index: 2;
}
