.root {
    display: flex;
    flex-direction: column;
}

.root.active {
    color: var(--color-brand-black);
}

.root.inactive {
    color: var(--color-brand-steel);
}

.root:hover {
    color: var(--color-brand-titanium);
}

.footerRoot {
    color: var(--color-brand-white);
}

.footerRoot.active {
    color: var(--color-brand-white);
}

.footerRoot.inactive {
    color: var(--color-brand-titanium);
}

.footerRoot.label {
    color: var(--color-brand-white);
}

.label {
    display: flex;
    flex-direction: row;
    padding: var(--spacing-size-two) 0;
    align-items: flex-start;
    cursor: pointer;
}

.icon {
    display: flex;
    margin: var(--spacing-size-one) 0 0 var(--spacing-size-one);
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.icon.active {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.subItems {
    padding-top: var(--spacing-size-two);
    padding-bottom: var(--spacing-size-two);
}
